export default {
    'ru-ua': {
        no_routes_title: 'Упс! Что-то пошло не так.',
        no_routes: 'К сожалению, на выбранную дату нет билетов. Попробуйте поискать на другие даты.', //, или найдите поезда с пересадкой.
        addults: 'Взрослый',
        addults_bus: 'Взрослый|Взрослых|Взрослых',
        children: 'Детский',
        children_bus: 'Детский|Детских|Детских',
        baby_bus: 'Младенец|Младенца|Младенцев',
        found: 'найдено',
        routes_count: 'рейс|рейса|рейсов',
        teenagers: 'Подросток',
        name: 'Имя',
        lastname: 'Фамилия',
        email: 'Email',
        date: 'Дата рождения',
        info: '* Имя и фамилию необходимо заполнять латинскими буквами',
        selected: 'Ваш пакет',
        not_selected: 'Выбрать',
        packages: {
            L: 'Пакет - Эконом',
            XL: 'Пакет - Стандарт',
            XXL: 'Пакет - Комфорт',
        },
        gender: {
            male: 'Мужской',
            female: 'Женский',
        },
        errors: {
            'fullname-en-error': 'Введите имя и фамилию на английском языке',
            'name-en-error': 'Только английские символы',
            'surname-en-error': 'Только английские символы',
            'name-error': 'Неверный символ',
            'surname-error': 'Неверный символ',
            'name-internationale-error': 'Неверный символ',
            'start_date-error': 'Некорректная дата',
            'end_date-error': 'Некорректная дата',
            'year-error': 'Некорректная дата',
            'email': 'Не правильный формат email',
            'phone': 'Не правильный формат телефона',
            'latin': 'Только латиница',
            'day': 'Некорректный день',
            'month': 'Некорректный месяц',
            'year': 'Некорректный год',
            'children': 'Возраст больше 13 лет',
            'old_age': 'Возраст меньше 50 лет',
            'student': 'Возраст больше 26 лет',
            'age_min_error': 'Возраст не может быть младше 18 лет',
            'age_max_error': 'Возраст не может быть старше 100 лет',
            'age_min_children_error': 'Возраст не может быть младше 1 года',
            'age_max_children_error': 'Возраст не может быть старше 14 лет',
            'date-is-same-error': 'Дата должна быть на день больше текущей',
            'date-is-before-error': 'Дата не может быть меньше текущей',
            'text-error': 'Только латиница',
            'text-ua-error': 'Только на Украинском',
            'text-min-error': 'Поле короче 2 символов',
            'empty-field-error': 'Поле не может быть пустым',
            'numeric-error': 'Поле может быть только числом',
            'mail-error': 'Неподдерживаемый формат email',
            'phone-error': 'Ошибка в формате номера',
            'born-date-error': 'Не верный формат даты',
            'document_series-error': 'Максимальная длина поля 2 символа',
            'document_number-error': 'Максимальная длина поля 6 символ',
            'vin-error': 'ВИН код должен состоять из 17 символов',
            'age-min-error': 'Возраст не может быть меньше 14',
            'age-max-error': 'Возраст не может быть больше 100',
            'age-does-not-match': 'Ребенок старше 13 лет',
            'code-error': 'ИНН должен состоять из 10 символов',
            'egrpou-error': 'ЕГРПОУ должен состоять из 8 символов',
            'reg-num-error': 'Регистрационный номер автомобиля не может быть больше 8 символов',
            'otp-error': 'Код не совпадает',
            'year-error': 'Год выпуска не может быть мень 1980 и старше 2023',
            'number-error': 'Поле может быть только числом',
            'character-range-error': 'Превысили лимит символов',
            'checkbox-error': 'Примите условия',
            'duration_date-error': 'Введите корректный диапазон дат',
            'password-error': 'Пароль должен содержать от 8 до 15 символов',
            'password-confirmation-error': 'Пароли не совпадают.',
            'avia_min_adult_age_error': 'Возраст не может быть младше 12 лет',
            'avia_max_adult_age_error': 'Возраст не может быть старше 120 лет',
            'avia_min_children_age_error': 'Возраст не может быть младше 2 лет',
            'avia_max_children_age_error': 'Возраст не может быть старше 12 лет',
            'avia_min_baby_age_error': 'Возраст не может быть младше 0 лет',
            'avia_max_baby_age_error': 'Возраст не может быть старше 2 лет',
            'expiration_date_error': 'Дата не может быть меньше текущей',
            'international_passport_error': 'Введите данные в формате: AB123456',
            'latin_and_digits_error': 'Только латинские символы и цифры',
        },
        year_abbr: 'ГГГГ',
        discount: 'Скидка',
        not_selected: 'Не выбрана',
        student: 'студент ISIC до 26 лет',
        old: 'Пенсионеры (от 60 лет)',
        discount: '5% карта постоянного клиента',
        student_title: 'Студенческий',
        traveling: 'Путешествующий',
        birthday: 'Дата рождения',
        insurance_sum: 'Страховая сумма',
        send: 'Отправить',
        months: {
            '01': 'Января',
            '02': 'Февраля',
            '03': 'Марта',
            '04': 'Апреля',
            '05': 'Мая',
            '06': 'Июня',
            '07': 'Июля',
            '08': 'Августа',
            '09': 'Сентября',
            '10': 'Октября',
            '11': 'Ноября',
            '12': 'Декабря',
        },
        cities_not_found: 'Пункт отправления не найдено',
        cities_popular: 'Популярные направления',
        place: 'Место',
        train: {
            all: 'Все',
            upper: 'Только верхние',
            lower: 'Только нижние',
            all_time: 'В любое время',
            morning: 'Утром (06-12)',
            day: 'Днем (12-18)',
            evening: 'Вечер (18-00)',
            night: 'Ночь (00-06)',
            class: {
                first: 'Люкс',
                second: 'Купе',
                third: 'Плацкарт',
                reserved: 'Сидячий',
                non_reserved: 'Общий',
                comfortable: 'Мягкий',
            },
            class_type: {
                'reserved_1': 'Класc 1',
                'reserved_2': 'Класc 2',
                'reserved_3': 'Класc 3',
                'firm_car': 'Фирменный',
                'not_firm_car': 'Не фирменный',
            },
            price: 'грн/билет',
            train_car: 'вагон',
            seats: {
                seats: 'место|места|мест',
                seats_upper: 'верхние|верхних|верхних',
                seats_lower: 'нижние|нижних|нижних',
                free: 'Доступные',
                selected: 'Выбранные',
                busy: 'Занятые',
            },
            select: 'Выбрать',
            selected: 'Выбран',
            maxNumberPassengers: 'Согласно правилам «Укрзалізниці» вы можете выбрать не более 4 мест в одном заказе.',
            maxPassengersPerTrip: 'Согласно правилам «Укрзалізниці» вы можете выбрать не более одного места в одном заказе на рейс туда/обратно',
            select_seats: 'Выберите хотя бы одно место.',
            error: 'Что-то пошло не так, обновите страницу и попробуйте еще раз.',
            refund_title: 'Гарантия возврата денег',
            sms_title: 'Sms напоминание',
            insurance_title: 'Комплексное страхование',
            passengers_count: 'Должен остаться хотя бы один пассажир.',
            passenger_ticket: 'билет',
        },
        avia: {
            with_baggage: 'С багажом',
            wothout_baggage: 'Без багажа',
            direct_flight: 'Прямой рейс',
            transfers: 'пересадка|пересадки|пересадок',
            withExtraLuggage: 'Дополнительный багаж',
            failed_popup: {
                title: 'Упс! Произошла ошибка!',
                errorReloadMessage: 'Попробуйте перезагрузить страницу и выбрать рейс заново.',
            },
            refund_reasons: {
                cancellation: 'Отмена рейса',
                death: 'Смерть пассажира',
                death_relative: 'Смерть близкого родственника',
                disease: 'Болезнь пассажира',
                no_show: 'Опоздание пассажира на рейс',
                other: 'Другое',
                user: 'Добровольный возврат',
                not_fly: 'Не полечу',
                visa: 'Отказ в визе',
                info: {
                    visa: {
                        title: 'Отказ в визе',
                        content: `
                            <p>Запрос не будет подан без (прикрепленного файла(ов)):</p>
                            <ol>
                                <li>сканирования первой страницы паспорта пассажира(ов),</li>
                                <li>официального отказа в выдаче визы на имя пассажира(ов) или отсканированного паспорта с соответствующей отметкой.</li>
                            </ol>
                            <p>Авиакомпания имеет право отклонить запрос или потребовать дополнительные документы.</p>
                        `,
                    },
                    disease: {
                        title: 'Болезнь пассажира',
                        content: `
                            <p>В случае болезни пассажира необходимо предоставить медицинский документ, соответствующий следующим требованиям:</p>
                            <ol>
                                <li>Наличие в медицинском документе четкого и читаемого названия учреждения, выдавшего данный документ,</li>
                                <li>Наличие реквизитов данного учреждения,</li>
                                <li>Наличие печати учреждения, выдавшего медицинский документ,</li>
                                <li>Четко читаемая должность и фамилия лица, выдавшего медицинский документ,</li>
                                <li>Наличие даты выдачи медицинского документа,</li>
                                <li>Соответствие дат болезни, указанных в медицинском документе, датам перевозки.</li>
                            </ol>
                            <p>В случае отсутствия медицинской справки или несоответствия требованиям агентство оставляет за собой право не отправлять запрос в авиакомпанию.</p>
                            <p>Авиакомпания имеет право отклонить запрос или потребовать дополнительные документы.</p>
                        `,
                    },
                    death: {
                        title: 'Смерть пассажира',
                        content: `
                            <p>Запрос не будет подан без (прикрепленного файла(ов)):</p>
                            <ol>
                                <li>Необходимо приложить документ, подтверждающий смерть пассажира.</li>
                            </ol>
                            <p>Авиакомпания имеет право отклонить запрос или потребовать дополнительные документы.</p>
                        `,
                    },
                    death_relative: {
                        title: 'Смерть близкого родственника',
                        content: `
                            <p>Запрос не будет подан без (прикрепленного файла(ов)):</p>
                            <ol>
                                <li>Необходимо приложить документ, подтверждающий смерть родственника а также подтверждение родства.</li>
                            </ol>
                            <p>Авиакомпания имеет право отклонить запрос или потребовать дополнительные документы.</p>
                        `,
                    },
                    cancellation: {
                        title: 'Отмена или задержка рейса более чем на 3 часа',
                        content: `
                            <p>Для ускорения процесса рассмотрения заявки на возврат прикрепите подтверждение отмены/задержки рейса.</p>
                            <p>В случае отсутствия документа, агентство самостоятельно проверяет факт отмены/задержки рейса, что, как правило, увеличивает сроки возврата средств.</p>
                        `
                    }
                },
            },
        },
        no_files: 'Файл не выбран',
        selected_files: 'Выбрано файлов:',
    },
    'uk-ua': {
        no_routes_title: 'Упс! Щось пішло не так.',
        no_routes: 'На жаль, на вибрану вами дату немає квитків. Спробуйте пошук на інші дати.', //, або знайдіть поїзди з пересадкою
        addults: 'Дорослий',
        addults_bus: 'Дорослий|Дорослих|Дорослих',
        children: 'Дитячий',
        children_bus: 'Дитячий|Дитячих|Дитячих',
        baby_bus: 'Немовля|Немовляти|Немовлят',
        found: 'знайдено',
        routes_count: 'рейс|рейса|рейсів',
        teenagers: 'Підліток',
        name: 'Ім\'я',
        lastname: 'Прізвище',
        email: 'Email',
        date: 'Дата народження',
        info: '* Ім\'я та прізвище необхідно заповнювати латинськими літерами',
        selected: 'Ваш пакет',
        not_selected: 'Обрати',
        packages: {
            L: 'Пакет - Економ',
            XL: 'Пакет - Стандарт',
            XXL: 'Пакет - Комфорт',
        },
        gender: {
            male: 'Чоловіча',
            female: 'Жіноча',
        },
        errors: {
            'fullname-en-error': 'Введіть ім\'я та прізвище англійською мовою.',
            'name-en-error': 'Тільки англійські символи',
            'surname-en-error': 'Тільки англійські символи',
            'name-error': 'Неправильний символ',
            'surname-error': 'Неправильний символ',
            'name-internationale-error': 'Неправильний символ',
            'email': 'Неправильний формат email',
            'phone': 'Неправильний формат телефону',
            'latin': 'Тільки латиниця',
            'day': 'Некоректний день',
            'month': 'Некоректний місяць',
            'year': 'Некоректний рік',
            'start_date-error': 'Некоректна дата',
            'end_date-error': 'Некоректна дата',
            'year-error': 'Некоректна дата',
            'children': 'Вік більше 13 років',
            'old_age': 'Вік менше 50 років',
            'student': 'Вік більше 26 років',
            'age_min_error': 'Вік не може бути молодшим 18 років',
            'age_max_error': 'Вік не може бути старшим 100 років',
            'age_min_children_error': 'Вік не може бути молодшим 1 року',
            'age_max_children_error': 'Вік не може бути старшим 14 років',
            'date-is-same-error': 'Дата повинна бути на день більшою за поточну',
            'date-is-before-error': 'Дата не може бути меншою за поточну',
            'text-error': 'Тільки латиниця',
            'text-ua-error': 'Тільки Українською',
            'text-min-error': 'Поле коротше 2 символiв',
            'empty-field-error': 'Поле не може бути порожнім',
            'document_series-error': 'Максимальна довжина поля 2 символи',
            'document_number-error': 'Максимальна довжина поля 6 символ',
            'vin-error': 'ВIН код повинен складатися з 17 символів',
            'numeric-error': 'Поле може бути лише числом',
            'mail-error': 'Непідтримуваний формат email',
            'phone-error': 'Помилка у форматі номера',
            'born-date-error': 'Неправильний формат дати',
            'age-min-error': 'Вік не може бути меншим 14',
            'age-max-error': 'Вік не може перевищувати 100',
            'age-does-not-match': 'Дитина старше 13 років',
            'code-error': 'ІПН повинен складатися з 10 символів',
            'egrpou-error': 'ЄДРПОУ повинен складатися з 8 символів',
            'reg-num-error': 'Реєстраційний номер автомобіля 8 символів',
            'otp-error': 'Код не збігається',
            'year-error': 'Не менше 1980 і старше 2023',
            'number-error': 'Поле може бути лише числом',
            'character-range-error': 'Перевищили ліміт символів',
            'checkbox-error': 'Прийміть умови',
            'duration_date-error': 'Введіть правильний діапазон дат',
            'password-error': 'Пароль повинен містити від 8 до 15 символів.',
            'password-confirmation-error': 'Паролі не співпадають.',
            'avia_min_adult_age_error': 'Вік не може бути молодшим 12 років',
            'avia_max_adult_age_error': 'Вік не може бути старшим 120 років',
            'avia_min_children_age_error': 'Вік не може бути молодшим 2 років',
            'avia_max_children_age_error': 'Вік не може бути старшим 12 років',
            'avia_min_baby_age_error': 'Вік не може бути молодшим 0 років',
            'avia_max_baby_age_error': 'Вік не може бути старшим 2 років',
            'expiration_date_error': 'Дата не може бути меншою за поточну',
            'international_passport_error': 'Введіть дані у форматі: AB123456',
            'latin_and_digits_error': 'Тільки латинські символи та цифри',
        },
        year_abbr: 'РРРР',
        discount: 'Знижка',
        not_selected: 'Обрати',
        student: 'студент ISIC до 26 років',
        old: 'Пенсіонери (від 60 років)',
        discount: '5% картка постійного клієнта',
        student_title: 'Студентський',
        traveling: 'Подорожуючий',
        birthday: 'Дата народження',
        insurance_sum: 'Страхова сума',
        send: 'Відправити',
        months: {
            '01': 'Січня',
            '02': 'Лютого',
            '03': 'Березня',
            '04': 'Квітня',
            '05': 'Травня',
            '06': 'Червня',
            '07': 'Липня',
            '08': 'Серпня',
            '09': 'Вересня',
            '10': 'Жовтня',
            '11': 'Листопада',
            '12': 'Грудня',
        },
        cities_not_found: 'Пункт відправлення не знайдено',
        cities_popular: 'Популярні напрямки',
        place: 'Мiсце',
        train: {
            all: 'Всі',
            upper: 'Тільки верхні',
            lower: 'Тільки ніжні',
            all_time: 'Будь-який час ',
            morning: 'Ранок (06-12)',
            day: 'День (12-18)',
            evening: 'Вечір (18-00)',
            night: 'Ніч (00-06)',
            class: {
                first: 'Люкс',
                second: 'Купe',
                third: 'Плацкарт',
                reserved: 'Сидячий',
                non_reserved: 'Загальний',
                comfortable: 'М\'який',
            },
            class_type: {
                'reserved_1': 'Клас 1',
                'reserved_2': 'Клас 2',
                'reserved_3': 'Клас 3',
                'firm_car': 'Фірмовий',
                'not_firm_car': 'Не фірмовий',
            },
            price: 'грн/квиток',
            train_car: 'вагон',
            seats: {
                seats: 'місце|місця|мicць',
                seats_upper: 'верхнє|верхнiх|верхніх',
                seats_lower: 'нижнє|нижніх|нижніх',
                free: 'Доступні',
                selected: 'Обрані',
                busy: 'Недоступні',
            },
            select: 'Обрати',
            selected: 'Обрано',
            maxNumberPassengers: 'Згідно з правилами «Укрзалізниці», ви можете вибрати не більше 4 місць в одному замовленні.',
            maxPassengersPerTrip: 'Відповідно до правил Укрзалізниці, ви можете вибрати не більше одного місця в одному замовленні на рейс туди/назад',
            select_seats: 'Виберіть хоча б одне місце.',
            error: 'Щось пішло не так, оновіть сторінку і спробуйте ще раз.',
            refund_title: 'Гарантія повернення грошей',
            sms_title: 'Sms нагадування',
            insurance_title: 'Комплексне страхування',
            passengers_count: 'Має залишитися хоча б один пасажир.',
            passenger_ticket: 'квиток',
        },
        avia: {
            with_baggage: 'З багажем',
            without_baggage: 'Без багажу',
            direct_flight: 'Прямий рейс',
            transfers: 'пересадка|пересадки|пересадок',
            withExtraLuggage: 'Додатковий багаж',
            failed_popup: {
                title: 'Ой! Виникла помилка!',
                errorReloadMessage: 'Спробуйте перезавантажити сторінку і обрати рейс знову.',
            },
            refund_reasons: {
                cancellation: 'Скасування рейсу',
                death: 'Смерть пасажира',
                death_relative: 'Смерть близького родича',
                disease: 'Хвороба пасажира',
                no_show: 'Запізнення пасажира на рейс',
                other: 'Інше',
                user: 'Добровільний повернення',
                not_fly: 'Не полечу',
                visa: 'Відмова в візі',
                info: {
                    visa: {
                        title: 'Відмова у візі',
                        content: `
                            <p>Запит не буде поданий без (прикріпленого файлу(ів)):</p>
                            <ol>
                                <li>сканування першої сторінки паспорта пасажира(ів),</li>
                                <li>офіційної відмови у видачі візи на ім'я пасажира(ів) або відсканованого паспорта з відповідною відміткою.</li>
                            </ol>
                            <p>Авіакомпанія має право відхилити запит або вимагати додаткових документів.</p>
                        `,
                    },
                    disease: {
                        title: 'Хвороба пасажира',
                        content: `
                            <p>У разі хвороби пасажира необхідно надати медичний документ, що відповідає таким вимогам:</p>
                            <ol>
                                <li>Наявність у медичному документі чіткої і читабельної назви установи, що видала цей документ,</li>
                                <li>Наявність реквізитів цієї установи,</li>
                                <li>Наявність печатки установи, що видала медичний документ,</li>
                                <li>Чітко читабельна посада та прізвище особи, що видала медичний документ,</li>
                                <li>Наявність дати видачі медичного документа,</li>
                                <li>Відповідність дат хвороби, зазначених у медичному документі, датам перевезення.</li>
                            </ol>
                            <p>У разі відсутності медичної довідки або невідповідності вимогам агентство залишає за собою право не відправляти запит до авіакомпанії.</p>
                            <p>Авіакомпанія має право відхилити запит або вимагати додаткових документів.</p>
                        `,
                    },
                    death: {
                        title: 'Смерть пасажира',
                        content: `
                            <p>Запит не буде поданий без (прикріпленого файлу(ів)):</p>
                            <ol>
                                <li>Необхідно додати документ, що підтверджує смерть пасажира.</li>
                            </ol>
                            <p>Авіакомпанія має право відхилити запит або вимагати додаткових документів.</p>
                        `,
                    },
                    death_relative: {
                        title: 'Смерть близького родича',
                        content: `
                            <p>Запит не буде поданий без (прикріпленого файлу(ів)):</p>
                            <ol>
                                <li>Необхідно додати документ, що підтверджує смерть родича, а також підтвердження родинних зв'язків.</li>
                            </ol>
                            <p>Авіакомпанія має право відхилити запит або вимагати додаткових документів.</p>
                        `,
                    },
                    cancellation: {
                        title: 'Скасування або затримка рейсу більше ніж на 3 години',
                        content: `
                            <p>Для прискорення процесу розгляду заявки на повернення коштів прикріпіть підтвердження скасування/затримки рейсу.</p>
                            <p>У разі відсутності документа агентство самостійно перевіряє факт скасування/затримки рейсу, що, як правило, збільшує терміни повернення коштів.</p>
                        `
                    }
                },
            },
        },
        no_files: 'Файл не обрано',
        selected_files: 'Вибрано файлів:',
    }
}