'use strict';

import { Util } from '../../Common/Util';
import { BusSearchModule } from './BusModule/BusSearchModule';
import PassengersType from './Checkout/Data/PassengersType';

class Bus {
    constructor() {
        this._rootWrapper = $('.bus-search-module');

        this._route = `${Util.getLocalization()}bus/search/f/`;
        this._lang = $('html').attr('lang');

        this._init();
    }

    _init() {
        this._busSearchModule = new BusSearchModule({
            page: this._rootWrapper,
            route: this._route,
            handler: this._searchTickets,
            error: this._searchTicketsError,
            success: this._searchTicketsSuccess,
        });
    }

    _searchTickets(event) {
        event.preventDefault();

        const cityFrom = this._cityFrom.getSelectedCity();
        const cityTo = this._cityTo.getSelectedCity();
        const startDate = this._datePicker.getStartDate();
        const endDate = this._datePicker.getEndDate();
        const passengers = this._passengers.getPassengersCount();

        const date = endDate ? `${startDate}_${endDate}` : startDate;

        let route = `${this._route}${cityFrom.val.replace(' ', '-')}/${cityTo.val.replace(' ', '-')}/${cityFrom.id}/${cityTo.id}/${date}/${passengers[PassengersType.ADDULTS]}/${passengers[PassengersType.CHILDREN]}/`;

        window.location = route;
    }

    _searchTicketsSuccess(data) {
        console.log(data.message);
    }

    _searchTicketsError(data) {
        console.error(data.message);
    }
}

$(() => {
    const busPage = new Bus;
});